import styled from 'styled-components'

export const A = styled.a`
    line-height: 1.2em;
    :hover {
        :hover {
            opacity: 0.7;
        }
    }
`
