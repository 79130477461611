import React from 'react'
import GoogleMapReact from 'google-map-react'
import { useTranslations } from 'i18n/useTranslations'

const Marker = () => {
    const translations = useTranslations()
    return (
        <div
            style={{
                color: 'black',
                background: 'white',
                padding: '15px 10px',
                display: 'inline-flex',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '100%',
                transform: 'translate(-50%, -50%)',
            }}
        >
            {translations.logo}
        </div>
    )
}

class SimpleMap extends React.Component {
    static defaultProps = {
        center: {
            lat: 48.843801,
            lng: 26.204982,
        },
        zoom: 15,
    }

    render() {
        const mapOptions = {
            styles: [
                { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
                {
                    elementType: 'labels.text.stroke',
                    stylers: [{ color: '#242f3e' }],
                },
                {
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#746855' }],
                },
                {
                    featureType: 'administrative.locality',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#d59563' }],
                },
                {
                    featureType: 'poi',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#d59563' }],
                },
                {
                    featureType: 'poi.park',
                    elementType: 'geometry',
                    stylers: [{ color: '#263c3f' }],
                },
                {
                    featureType: 'poi.park',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#6b9a76' }],
                },
                {
                    featureType: 'road',
                    elementType: 'geometry',
                    stylers: [{ color: '#38414e' }],
                },
                {
                    featureType: 'road',
                    elementType: 'geometry.stroke',
                    stylers: [{ color: '#212a37' }],
                },
                {
                    featureType: 'road',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#9ca5b3' }],
                },
                {
                    featureType: 'road.highway',
                    elementType: 'geometry',
                    stylers: [{ color: '#746855' }],
                },
                {
                    featureType: 'road.highway',
                    elementType: 'geometry.stroke',
                    stylers: [{ color: '#1f2835' }],
                },
                {
                    featureType: 'road.highway',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#f3d19c' }],
                },
                {
                    featureType: 'transit',
                    elementType: 'geometry',
                    stylers: [{ color: '#2f3948' }],
                },
                {
                    featureType: 'transit.station',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#d59563' }],
                },
                {
                    featureType: 'water',
                    elementType: 'geometry',
                    stylers: [{ color: '#17263c' }],
                },
                {
                    featureType: 'water',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#515c6d' }],
                },
                {
                    featureType: 'water',
                    elementType: 'labels.text.stroke',
                    stylers: [{ color: '#17263c' }],
                },
            ],
        }

        return (
            <GoogleMapReact
                options={mapOptions}
                bootstrapURLKeys={{
                    key: 'AIzaSyBZhp4ZnniD35BMQX6miz68sMD-HUiT85U',
                }}
                defaultCenter={this.props.center}
                defaultZoom={this.props.zoom}
            >
                <Marker lat={48.843801} lng={26.204982} />
            </GoogleMapReact>
        )
    }
}

export default SimpleMap
