import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import SimpleMap from 'components/ui-kit/map'
import { breakpoints } from 'components/ui-kit/globalValue'
import { Page } from 'components/layout/Page'
import { Title } from 'components/ui-kit/title'
import { Container } from 'components/ui-kit/Container'
import { ContactElement } from 'components/ui-kit/ContactElement'
import { Button } from 'components/ui-kit/Button'
import { useTranslations } from 'i18n/useTranslations'
import { contactInformation } from 'data/contactInformation'
import { Form } from 'components/ui-kit/Form'

const inputLineHeight = `20`

const InputElement = (props) => `
    width: 100%;
    padding: 4px 6px;
    margin: 5px 0;
    border: none;
    box-sizing:border-box;
    border-bottom: 2px solid #222;
    font-size: 16px;
    line-height: ${inputLineHeight}px;
    :focus {
        background-color: #ddd;
        outline:none;
    }

`

const NameInput = styled.input`
    ${InputElement}
`
const PhoneInput = styled.input`
    ${InputElement}
`
const EmailInput = styled.input`
    ${InputElement}
`
const MessageInput = styled.textarea`
    min-width: 100%;
    max-width: 100%;
    height: ${inputLineHeight * 2}px;
    ${InputElement}
`

const ContactBox = styled.div`
    @media (min-width: ${breakpoints.tablet}) {
        display: flex;
        justify-content: space-around;
    }
`
const ContactColumn = styled.div`
    @media (min-width: ${breakpoints.tablet}) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex: 0 0 auto;
        width: 30%;
    }
`
const ButtonStyled = styled(Button)`
    min-width: 200px;
    display: block;
    margin: 5px auto;
    text-align: center;
    box-sizing: border-box;
    :disabled {
        opacity: 0.5;
    }
`
const MapBox = styled.div`
    padding-top: 20px;
    height: 50vh;
    @media (min-width: ${breakpoints.tablet}) {
        height: 70vh;
        padding-top: 30px;
    }
`
const Report = styled.div`
    min-height: 35px;
    text-align: center;
`

const ContactPage = () => {
    const translations = useTranslations()
    return (
        <Page>
            <Helmet>
                <title>
                    {translations.contacts} - {translations.logo} -
                    {translations.siteTitle}
                </title>
                <meta
                    name="description"
                    content="СПСК - Скала-подільський Спецкарʼєр.
                    Виконавчий директор
                    Шашкіна Наталія Василівна
                    '+38 (067)354 32 74'
                    "
                />
            </Helmet>
            <Container>
                <Title as="h1" view="underlined">
                    {translations.contacts}
                </Title>
                <ContactBox>
                    <ContactColumn>
                        <ContactElement
                            position={translations.ceo}
                            phone={contactInformation.ceoPhoneNumber}
                        >
                            {translations.ceoName}
                        </ContactElement>
                        <ContactElement
                            position={translations.financialDepartment}
                            phone={
                                contactInformation.financialDepartmentPhoneNumber
                            }
                        >
                            {translations.financialDepartmentName}
                        </ContactElement>
                    </ContactColumn>
                    <ContactColumn>
                        <ContactElement
                            position={translations.supplyDepartment}
                            phone={
                                contactInformation.supplyDepartmentPhoneNumber
                            }
                        >
                            {translations.supplyDepartmentName}
                        </ContactElement>
                        <ContactElement
                            position={translations.salesDepartment}
                            phone={
                                contactInformation.salesDepartmentPhoneNumber
                            }
                        >
                            {translations.salesDepartmentName}
                        </ContactElement>
                    </ContactColumn>
                    <ContactColumn>
                        <Form>
                            {(responseStatus, isSending) => (
                                <>
                                    <Title as="h4" view="underlined">
                                        {translations.writeToUs}
                                    </Title>
                                    <NameInput
                                        aria-label="Введіть Ваше ім'я"
                                        type="text"
                                        name="name"
                                        required
                                        minlength="1"
                                        placeholder={translations.name}
                                    />
                                    <PhoneInput
                                        aria-label="Введіть Номер телефону"
                                        type="text"
                                        name="phone"
                                        required
                                        minlength="1"
                                        placeholder={translations.phoneNumber}
                                    />
                                    <EmailInput
                                        aria-label="Введіть вашу електронну адресу"
                                        type="text"
                                        name="email"
                                        placeholder="Email"
                                    />
                                    <MessageInput
                                        aria-label="Введіть повідомлення"
                                        name="message"
                                        placeholder={translations.message}
                                    />

                                    <ButtonStyled
                                        aria-label="Відправити запит"
                                        view="light"
                                        type="submit"
                                        disabled={isSending}
                                    >
                                        {translations.submit}
                                    </ButtonStyled>
                                    <Report>{responseStatus}</Report>
                                </>
                            )}
                        </Form>
                    </ContactColumn>
                </ContactBox>
            </Container>
            <MapBox>
                <SimpleMap />
            </MapBox>
        </Page>
    )
}
export default ContactPage
