import styled from 'styled-components'
import React from 'react'

import { Title } from '../ui-kit/title'
import { breakpoints } from 'components/ui-kit/globalValue'
import { A } from 'components/ui-kit/A'

const Position = styled(Title)`
    font-size: 18px;
    @media (min-width: ${breakpoints.tablet}) {
        font-size: 20px;
    }
`
const WorkerName = styled.p`
    padding: 10 20px;
    font-size: 20px;
    text-align: center;
    @media (min-width: ${breakpoints.tablet}) {
        font-size: 16px;
    }
`
const Phone = styled(A)`
    display: block;
    padding: 20px 20px;
    font-size: 20px;
    text-decoration: none;
    text-align: center;
    color: #222;
    @media (min-width: ${breakpoints.tablet}) {
        font-size: 16px;
    }
`
const Box = styled.div`
    padding: 10px 0;
    @media (min-width: ${breakpoints.tablet}) {
        padding: 0;
    }
`

export const ContactElement = (props) => (
    <Box>
        <Position as="h4" view="underlined">
            {props.position}
        </Position>
        <WorkerName>{props.children}</WorkerName>
        <Phone href={`tel:${props.phone}`}>{props.phone} </Phone>
    </Box>
)
