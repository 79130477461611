import React, { useState } from 'react'
import { useTranslations } from 'i18n/useTranslations'
import styled from 'styled-components'

const ContactFormElement = styled.form`
    width: 100%;
`

export const Form = ({ children }) => {
    const [responseStatus, setResponseStatus] = useState(null)
    const [isSending, setIsSending] = useState(false)
    const translations = useTranslations()
    return (
        <ContactFormElement
            onSubmit={(e) => {
                e.preventDefault()
                setIsSending(true)
                fetch(process.env.GATSBY_FORM_URL, {
                    method: 'POST',
                    body: new FormData(e.target),
                })
                    .then((response) => {
                        if (!response.ok) {
                            return Promise.reject()
                        }
                        return response.text()
                    })
                    .then((text) => {
                        if (text !== 'SUCCESS') {
                            setIsSending(false)
                            setResponseStatus(translations.serverError)
                            return
                        }
                        return setResponseStatus(translations.done)
                    })
                    .catch(() => {
                        setIsSending(false)
                        setResponseStatus(translations.sendError)
                        return
                    })
            }}
        >
            {children(responseStatus, isSending)}
        </ContactFormElement>
    )
}
